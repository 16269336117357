export const backUrl = 'https://remote-back.wizito.com'
const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const id = location().pathname.replace('/', '')

export function getDisplay() {
  return fetch(`${backUrl}/${id}`).then(res => res.json())
}

export function setAction(action, file) {
  console.log(action, file)
  return fetch(`${backUrl}/${id}/action/${action}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: file ? JSON.stringify({ file }) : undefined })
}
