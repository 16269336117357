import React from 'react'

export default function Title({ children, style, className }) {
  return (
    <div
      style={style}
      className={`text-2xl mx-3 mb-10 relative font-bold leading-tight text-gray-800 ${className}`}>
      {children}
    </div>
  )
}
