import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, children, style, className, variant = 'default', Icon, validation, value, href }) {
  const [loading, setLoading] = useState(false)
  
  const iconStyles = {
    default: 'text-2xl stroke-2 mr-2 -ml-1',
    secondary: 'text-2xl stroke-2 mr-2 -ml-1'
  }
  const styles = {
    default: `text-lg font-bold py-3 px-7 bg-orange hover:bg-orange-dark text-white`,
    secondary: `text-lg font-bold py-3 px-7 bg-blue-dark bg-opacity-90 hover:opacity-75 text-white`
  }
  const disabled = validation && !validation.test(String(value))
  return (
    <a href={href}>
      <button
        onClick={onClick}
        disabled={disabled || loading}
        style={style}
        className={`relative flex justify-center items-center m-4 align-middle focus:outline-none select-none flex-shrink-0 transition-all duration-100 rounded-md shadow-sm hover:shadow ${styles[variant]} ${className} ${disabled && 'opacity-40 pointer-events-none'}`}>
        {Icon && <Icon className={iconStyles[variant]} />}
        {children}
        {loading &&
          <div className={`absolute w-full h-full p-1 bg-gray-100`}>
            <FiLoader className="stroke-1 rotating box-border w-full h-full" />
          </div>
        }
      </button>
    </a>
  )
}