import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

export default function Errors({ children }) {
  return (
    <div className="fixed h-screen w-screen overflow-hidden flex flex-col justify-center items-center opacity-75 text-gray-600 p-8">
      <FiAlertTriangle className="h-12 w-12 stroke-1 box-border" />
      {children && <div className="font-light text-lg">{children}</div>}
    </div>
  )
}