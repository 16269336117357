import React, { useRef, useState } from 'react'
import reduceBlob from 'image-blob-reduce'
import Loader from './Loader'
import Errors from './Errors'
import Button from './Button'
import { FiMousePointer } from 'react-icons/fi'
// https://github.com/nodeca/image-blob-reduce/issues/15
let heic2any
if (typeof window !== `undefined`) import('heic2any').then(e => { heic2any = e.default })


export default function Upload({ upload, button }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const input = useRef(null)
  const handleFiles = async e => {
    const photo = e.target.files[0]
    try {
      setLoading(true)
      if (photo.type === 'image/heif') {
        photo.converted = await heic2any({ blob: photo, toType: 'image/jpeg', quality: 0.8 })
      }
      const compressed = await reduceBlob().toCanvas(photo.converted || photo, { max: 1500 }).catch(e => photo)
      upload(compressed.toDataURL('image/jpeg'))
    } catch (error) {
      console.error(error)
      setError(true)
    }
    setLoading(false)
  }
  if (error)
    return <Errors>Erreur lors de la récupération de votre photo</Errors>
  if (loading !== false)
    return <Loader />
  return (
    <>
      <Button className="my-4" onClick={() => input.current.click()}>{button || 'Choisir'}</Button>
      <input
        className="fixed -z-10 hidden"
        type="file"
        ref={input}
        onChange={handleFiles}
        accept="image/*" />
    </>
  )
}
