import useInterval from '@use-it/interval'
import React, { useState } from 'react'
import JsxParser from 'react-jsx-parser'
import { getDisplay, setAction } from '../api'
import Button from '../components/Button'
import Title from '../components/Title'
import Errors from '../components/Errors'
import Loader from '../components/Loader'
import Upload from '../components/Upload'
import Checkbox from '../components/Checkbox'
import fi from 'react-icons/fi/index'

let holdSync = false

const windowHeight = (typeof window !== `undefined`) ? window.innerHeight : 700

export default function Home() {
  const [state, setState] = useState('wait')
  const [jsx, setJsx] = useState()
  const [value, setValue] = useState('')
  useInterval(() => {
    if (holdSync) return
    getDisplay().then(r => {
      if (holdSync) return
      setJsx(r.jsx)
      setState(r.jsx ? 'live' : 'wait' )
    })
    .catch(e => setState('error'))
  }, 1000)
  const submit = async (v, f) => {
    if (holdSync) return
    holdSync = true
    setState('wait')
    await setAction(v, f).catch(() => { holdSync = false })
    setValue(null)
    setTimeout(() => { holdSync = false }, 1000)
  } 
  if (state === 'wait')
    return <div className="flex flex-col justify-center items-center h-screen w-screen"><Loader /></div>
  if (state === 'error')
    return <div className="flex flex-col justify-center items-center h-screen w-screen"><Errors>Accès introuvable</Errors></div>
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen text-center">
      <JsxParser
        blacklistedAttrs={[]}
        blacklistedTags={[]}
        renderInWrapper={false}
        bindings={{
          action: (action, e) => submit(action),
          onChange: e => setValue(e.target ? e.target.value : e),
          submit: _ => submit(value),
          window: (typeof window !== `undefined`) ? window : {},
          windowHeight,
          upload: f => submit('upload', f),
          value,
          notValue: !value,
          ...fi
        }}
        components={{ Button, Title, Checkbox, Upload, ...fi }}
        jsx={jsx}
      />
    </div>
  )
}
