import React from 'react'
import { FiCheck } from 'react-icons/fi'

export default function Checkbox({ checked, onClick, className }) {
  return (
    <div
      onClick={onClick.bind(null, !checked)}
      className={`flex justify-center items-center flex-shrink-0 w-10 h-10 border shadow-sm rounded-sm border-gray-200 text-gray-400 cursor-pointer hover:border-gray-200 ${className}`}>
      {checked && <FiCheck className="stroke-2 w-7 h-7" />}
    </div>
  )
}
